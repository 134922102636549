// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArTag-module--ar-gradient-blue--zPhOW";
export var arGradientLight = "ArTag-module--ar-gradient-light--SO7z8";
export var arH1 = "ArTag-module--ar-h1--AToeJ";
export var arH1Mobile = "ArTag-module--ar-h1-mobile--BdGwx";
export var arH2 = "ArTag-module--ar-h2--46emw";
export var arH2Mobile = "ArTag-module--ar-h2-mobile--65PG5";
export var arH3 = "ArTag-module--ar-h3--colPM";
export var arH3Mobile = "ArTag-module--ar-h3-mobile--S4bEh";
export var arH4 = "ArTag-module--ar-h4--yFrWb";
export var arH4Mobile = "ArTag-module--ar-h4-mobile--tETWS";
export var arH5 = "ArTag-module--ar-h5--oAraO";
export var arH5Mobile = "ArTag-module--ar-h5-mobile--CrIh9";
export var arLinkDesktop = "ArTag-module--ar-link-desktop--J7gvQ";
export var arLinkMobile = "ArTag-module--ar-link-mobile--A0n3D";
export var arParagraphBase = "ArTag-module--ar-paragraph-base--SYMvl";
export var arParagraphBaseMedium = "ArTag-module--ar-paragraph-base-medium--oYBFY";
export var arParagraphSmall = "ArTag-module--ar-paragraph-small--ywv9C";
export var arParagraphSmallMedium = "ArTag-module--ar-paragraph-small-medium--EwKvQ";
export var arParagraphXl = "ArTag-module--ar-paragraph-xl--YSpdT";
export var arParagraphXlMedium = "ArTag-module--ar-paragraph-xl-medium--j2e5O";
export var arParagraphXsmall = "ArTag-module--ar-paragraph-xsmall--zV+HY";
export var arParagraphXsmallMedium = "ArTag-module--ar-paragraph-xsmall-medium--81Hcg";
export var arQuote = "ArTag-module--ar-quote--dQY3b";
export var arQuoteMobile = "ArTag-module--ar-quote-mobile--njRmg";
export var arTransition = "ArTag-module--ar-transition--SdkxX";
export var container = "ArTag-module--container--mfCv8";
export var small = "ArTag-module--small--KHBzj";
export var themeBlue = "ArTag-module--themeBlue--H1ZNu";
export var themeGray = "ArTag-module--themeGray--dwsdB";
export var themeRed = "ArTag-module--themeRed--gcoG2";