// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArExtraFeatures-module--ar-gradient-blue--4Gelt";
export var arGradientLight = "ArExtraFeatures-module--ar-gradient-light--jGebx";
export var arH1 = "ArExtraFeatures-module--ar-h1--MFtNM";
export var arH1Mobile = "ArExtraFeatures-module--ar-h1-mobile--asXeg";
export var arH2 = "ArExtraFeatures-module--ar-h2--7R5zB";
export var arH2Mobile = "ArExtraFeatures-module--ar-h2-mobile--GR-6R";
export var arH3 = "ArExtraFeatures-module--ar-h3--q8j3b";
export var arH3Mobile = "ArExtraFeatures-module--ar-h3-mobile--N07P3";
export var arH4 = "ArExtraFeatures-module--ar-h4--MBQ58";
export var arH4Mobile = "ArExtraFeatures-module--ar-h4-mobile--me9Cw";
export var arH5 = "ArExtraFeatures-module--ar-h5--8fNvU";
export var arH5Mobile = "ArExtraFeatures-module--ar-h5-mobile--K8XqY";
export var arLinkDesktop = "ArExtraFeatures-module--ar-link-desktop--OLHfg";
export var arLinkMobile = "ArExtraFeatures-module--ar-link-mobile--nsP42";
export var arParagraphBase = "ArExtraFeatures-module--ar-paragraph-base--2N2jK";
export var arParagraphBaseMedium = "ArExtraFeatures-module--ar-paragraph-base-medium--CHMnH";
export var arParagraphSmall = "ArExtraFeatures-module--ar-paragraph-small--nYr3L";
export var arParagraphSmallMedium = "ArExtraFeatures-module--ar-paragraph-small-medium--6GJY6";
export var arParagraphXl = "ArExtraFeatures-module--ar-paragraph-xl--3Sz13";
export var arParagraphXlMedium = "ArExtraFeatures-module--ar-paragraph-xl-medium--NNWj7";
export var arParagraphXsmall = "ArExtraFeatures-module--ar-paragraph-xsmall--3IAnL";
export var arParagraphXsmallMedium = "ArExtraFeatures-module--ar-paragraph-xsmall-medium--q4f4n";
export var arQuote = "ArExtraFeatures-module--ar-quote--M-mon";
export var arQuoteMobile = "ArExtraFeatures-module--ar-quote-mobile--KU8rW";
export var arTransition = "ArExtraFeatures-module--ar-transition--0aJjy";
export var container = "ArExtraFeatures-module--container--CAnzf";
export var smallSpaceBetween = "ArExtraFeatures-module--smallSpaceBetween--1rPGM";
export var tiles = "ArExtraFeatures-module--tiles--WykHF";