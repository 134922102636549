// extracted by mini-css-extract-plugin
export var algorithmicUnderwriting = "ArPlatformProducts-module--algorithmicUnderwriting--x3mdC";
export var algorithmicUnderwritingColumns = "ArPlatformProducts-module--algorithmicUnderwritingColumns--lV4Hv";
export var arGradientBlue = "ArPlatformProducts-module--ar-gradient-blue--Bl8PG";
export var arGradientLight = "ArPlatformProducts-module--ar-gradient-light--NigRQ";
export var arH1 = "ArPlatformProducts-module--ar-h1--txHig";
export var arH1Mobile = "ArPlatformProducts-module--ar-h1-mobile--xICbs";
export var arH2 = "ArPlatformProducts-module--ar-h2--ilIxc";
export var arH2Mobile = "ArPlatformProducts-module--ar-h2-mobile--T3yRq";
export var arH3 = "ArPlatformProducts-module--ar-h3--80484";
export var arH3Mobile = "ArPlatformProducts-module--ar-h3-mobile--wiTAP";
export var arH4 = "ArPlatformProducts-module--ar-h4--nnTVb";
export var arH4Mobile = "ArPlatformProducts-module--ar-h4-mobile--AGDYg";
export var arH5 = "ArPlatformProducts-module--ar-h5--cyoD1";
export var arH5Mobile = "ArPlatformProducts-module--ar-h5-mobile--gHTqp";
export var arLinkDesktop = "ArPlatformProducts-module--ar-link-desktop--7P7qD";
export var arLinkMobile = "ArPlatformProducts-module--ar-link-mobile--o-gUq";
export var arParagraphBase = "ArPlatformProducts-module--ar-paragraph-base--fkWh0";
export var arParagraphBaseMedium = "ArPlatformProducts-module--ar-paragraph-base-medium--mG+9O";
export var arParagraphSmall = "ArPlatformProducts-module--ar-paragraph-small--AeHyb";
export var arParagraphSmallMedium = "ArPlatformProducts-module--ar-paragraph-small-medium--S055i";
export var arParagraphXl = "ArPlatformProducts-module--ar-paragraph-xl--oYSIv";
export var arParagraphXlMedium = "ArPlatformProducts-module--ar-paragraph-xl-medium--PMTQS";
export var arParagraphXsmall = "ArPlatformProducts-module--ar-paragraph-xsmall--simOx";
export var arParagraphXsmallMedium = "ArPlatformProducts-module--ar-paragraph-xsmall-medium--sdmZ4";
export var arQuote = "ArPlatformProducts-module--ar-quote--L1RaG";
export var arQuoteMobile = "ArPlatformProducts-module--ar-quote-mobile--TrxiP";
export var arTransition = "ArPlatformProducts-module--ar-transition--6gQpY";
export var container = "ArPlatformProducts-module--container--MnLje";
export var content = "ArPlatformProducts-module--content--iTJUm";
export var contentWrapper = "ArPlatformProducts-module--contentWrapper--9gq-6";
export var contractBuilder = "ArPlatformProducts-module--contractBuilder--LKghw";
export var dataIngestion = "ArPlatformProducts-module--dataIngestion--hUZR0";
export var image = "ArPlatformProducts-module--image--vqp2s";
export var instantRiskTraging = "ArPlatformProducts-module--instantRiskTraging--pt1ny";
export var integrationsAndApi = "ArPlatformProducts-module--integrationsAndApi--3ECOT";
export var tag = "ArPlatformProducts-module--tag--IJ37V";
export var textOnTheLeft = "ArPlatformProducts-module--textOnTheLeft--0znjg";
export var textWithImage = "ArPlatformProducts-module--textWithImage--4jMEh";
export var underwritingWorkbench = "ArPlatformProducts-module--underwritingWorkbench--N4BWm";